import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
// Quill
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import katex from "katex";
import "katex/dist/katex.min.css";
import BlotFormatter from 'quill-blot-formatter';

import './styles.css';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
//import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Container, CssBaseline } from '@mui/material';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Badge from '@mui/material/Badge';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import Footer from './footer';
import ConfirmationDialog from './confirmation';
import Tooltip from '@mui/material/Tooltip';

// import {
//   createMuiTheme,
//   responsiveFontSizes,
//   MuiThemeProvider,
// } from "@mui/material/";

Quill.register({'modules/imageResize': ImageResize});
Quill.register({'modules/blotFormatter': BlotFormatter});

// let theme = createMuiTheme(); 
// theme = responsiveFontSizes(theme);

window.katex = katex;

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

   const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );
    console.log("Scroll to top");

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const drawerWidth = 140;
const navItems = ['Home', 'About', 'Contact'];
var data = "";

// ####################################################
// função do editor
export default function EditorX(props) {

  const { window } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  
  const [mobileOpen, setMobileOpen] = React.useState(false);

  //var quillRef = React.useRef();
  //var reactQuillRef = React.useRef();
  const [saveCount, setSaveCount] = React.useState(false);

  const reactQuillRef = useRef(null);
  const [editorState, setEditorState] = useState("");

  const history = useNavigate();

  // #######################################################################
  function storageInit() {
    console.log("Local storage init"); 
    document.body.style.zoom = "100%";

    // Ensure React-Quill reference is available:
    if (typeof reactQuillRef.getEditor !== 'function') { 
      console.log("Not is func");
      return; 
    }
    
    // var quillRef = reactQuillRef.getEditor();
    //if (quillRef !== null) this.quillRef = quillRef;

    // var range = quillRef.getSelection();
    // let position = range ? range.index : 0;
    //quillRef.insertText(0, 'Hello, World! ')

    var userid = localStorage.getItem("pubuser");
    var storage_name = userid + ".db";
    // Verifica se existe algum banco de dados local e qual é seu proprietário
    var localdata = localStorage.getItem(storage_name);
    // Verifica se tem dados armazenados localmente
    //localStorage.
    //console.log("dados: " + localdata);
    if (localdata) {
      console.log("tem");
      //var edtx = Quill.getEditor();
      //quillRef.pasteHTML(0, localdata)
      //quil.current.getEditor().insertText("Tá doido");
    } else {
      console.log("não tem"); 
    }
    setSaveCount(0);
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState); 
  };

  // const insertTable = () => {
  //   const editor = reactQuillRef.current.getEditor();
  //   const tableModule = editor.getModule("better-table");
  //   tableModule.insertTable(3, 3);
  // };

  useEffect(() => {

    // const editor = reactQuillRef.current.getEditor();
    // const toolbar = editor.getModule("toolbar");
    // toolbar.addHandler("table", () => {
    //   insertTable();
    // });


    storageInit(); // <- function that will run on every dependency update
    } ,[]) 

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("El")     
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    console.log("Close")   
  };

  //#################################################
  // Aqui salva o texto digitado
  const handleProfile = () => {
  };

  //#################################################
  // Aqui salva o texto digitado
  const handleSaveData = () => {
    setAnchorEl(null);
    var userid = localStorage.getItem("pubuser");
    var storage_name = userid + ".db";
    localStorage.setItem(storage_name, data);
    setSaveCount(0);
  };

  //#################################################
  // Aqui recupera dos dados
  const handleLoadData = () => {

    var quillRef = reactQuillRef.current.getEditor();

    var userid = localStorage.getItem("pubuser");
    var storage_name = userid + ".db";

    // Por enquanto vamos fazer com armazenamento local
    var localdata = localStorage.getItem(storage_name);
    // Verifica se tem dados armazenados localmente
    //console.log("dados: " + localdata);
    if (localdata) {
      quillRef.pasteHTML(0, localdata)
    } else {
      console.log("não tem"); 
    }

    // const editor = reactQuillRef.getEditor();
    const tableModule = quillRef.getModule("table-body");
    // tableModule.insertTable(3, 3);

    //let tableModule = quillRef.getModule('table-row');
    if (tableModule) {
      console.log("Table-body");  
      //tableModule.insertTable(3, 3)
    } else {
      console.log("None");  
    }

    setSaveCount(0);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    console.log("Logout");
    localStorage.removeItem("authUser");
    //console.log("Vai..");
    history('/');       
  };

  const handleMenuClick = () => {
    console.log("Menu click");
  }

  const handleConfirmation = () => {
    // Perform action upon confirmation
    console.log('Confirmed!');
  };

  const handleChangeHtml = (html) => {
    // Em qualquer edição vamos salvar em banco de dados para não haver perda
    // Temos de pensar na otimização desse processo

    // Problema 1 temos de desativar o resize para deletar a imagem ####
    // Vamos salvar em SQLite local e quando for sair da página ou perder o foco, 
    // joga para o BD
    console.log("Modificou");
    data = html;
    setSaveCount(1);
    // Marcar que o texto foi editado!!
    //console.log(data);
  }


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSaveData}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Sair</MenuItem>
    </Menu>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        EditorX
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} onClick={handleMenuClick}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return ( 
    (localStorage.getItem("authUser") ?
      <React.Fragment>
        <CssBaseline />
        <AppBar component="nav" position="sticky">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={handleDrawerToggle}
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' } }}
            >
              EditorX
            </Typography>
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search> */}
            <Box sx={{ flexGrow: 1 }} />

              <ConfirmationDialog
                title="Confirmation"
                description="Are you sure you want to proceed?"
                response={handleConfirmation}
              >
                {(showDialog) => (
                  <div>
                    <Tooltip title="Clear All Work">
                      <IconButton
                        size="large"
                        aria-label="load work"
                        color="inherit"
                        onClick={showDialog}
                        //onClick={handleClearAllData}
                      >
                        <ClearAllIcon />
                      </IconButton>
                    </Tooltip>            
                  </div>
                )}
              </ConfirmationDialog>
       
            <Tooltip title="Load Work">
              <IconButton
                size="large"
                aria-label="load work"
                color="inherit"
                onClick={handleLoadData}
                //disabled={true} // colocar um estado para evitar duplo click
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>            
            <Tooltip title="Save Work">
              <IconButton
                size="large"
                aria-label="show modification"
                color="inherit"
                onClick={handleSaveData}
              >
                <Badge badgeContent={saveCount} color="error">
                  <CloudUploadIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Box sx={{ display: { xs: 'block', md: 'block' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Divider id="back-to-top-anchor" />
        {renderMenu}
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Container>
          <Box sx={{ my: 2 }}>
            <ReactQuill
                ref={reactQuillRef}
                onChange={handleChangeHtml}
                modules={EditorX.modules}
                formats={EditorX.formats}
                bounds={'#root'}
              /> 
          </Box>
        </Container>   
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>         
        <Footer />
      </React.Fragment>
      : 
      <div style={{position: "absolute"}}>
        <Alert severity="error" style={{fontSize: 15}}>
          Not logged in.
        </Alert>
        <Footer />
      </div>
    ) // Fim do ternário
  );
}



/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
EditorX.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { align: ['', 'center', 'right', 'justify']},
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video', 'formula', 'table'],
    ['clean']
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  blotFormatter: {},
  //table: true,
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
EditorX.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'formula',
  'align',
  'table'
];
