import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.scss";

import AuthContainer from "./components/auth/AuthContainer";
import EditorX from './components/app/editorx';

function App() {
  return (
    <BrowserRouter>
      {/* <div className="header">
        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/editorx">Home</NavLink>
        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/login">Login</NavLink><small>(Access without token only)</small>
        <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/dashboard">Dashboard</NavLink><small>(Access with token only)</small>
      </div>     */}
      <div className="content">
        <Routes>
          <Route index element={< AuthContainer />} />
          <Route path="/editorx" element={< EditorX /> } /> 
        </Routes>
      </div>

    </BrowserRouter>
  );
}



export default App;
