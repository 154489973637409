import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

//require('dotenv').config();
const uri = 'https://pubevents.com.br/api'; //process.env.MONGO_URI;
//const uri = 'http://localhost:7001/api'; //process.env.MONGO_URI;

// import https from 'https'

// const headers = {};

// const httpsAgent = new https.Agent({
//   ca: fs.readFileSync('./certs/cert.pem'),
//   cert: fs.readFileSync('./certs/cert.pem'),
// })

// const data = await axios.get(url, { httpsAgent, headers })


// const httpsAgent = new https.Agent({
//   ca: fs.readFileSync("./resource/bundle.crt"),        
//   cert: fs.readFileSync("./resrouce/thirdparty.crt"),
//   key: fs.readFileSync("./resource/key.pem"), 
// })
// import config from '~/config'

// /**
//  * Axios default settings
//  */
// axios.defaults.baseURL = config.apiURL

// /**
//  * Disable only in development mode
//  */
// if (process.env.NODE_ENV === 'development') {
//   const httpsAgent = new https.Agent({
//     rejectUnauthorized: false,
//   })
//   axios.defaults.httpsAgent = httpsAgent
//   // eslint-disable-next-line no-console
//   console.log(process.env.NODE_ENV, `RejectUnauthorized is disabled.`)
// }
// const httpsAgent = new https.Agent({
//   rejectUnauthorized: false, // (NOTE: this will disable client verification)
//   cert: fs.readFileSync("./usercert.pem"),
//   key: fs.readFileSync("./key.pem"),
//   passphrase: "YYY"
// })

// axios.get(url, { httpsAgent })


export default axios.create({
  baseURL: uri,
});

root.render(
      <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

