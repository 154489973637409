//import axios from 'axios';
import axios from '../../index.js';

// export default axios.create({
//     //baseURL: 'https://pido.me/api',
//     baseURL: 'http://localhost:7001/api',
//     //baseURL: 'https://pido.rpgsoftware.com.br/api',
// });


export function registerUser(name, email, password) {
    try {
        return axios.post('/registeruser', {
            name: name,
            email: email,
            password: password
        })
    } catch (e) {
        console.log("Erro: " + e);
        return "Conflito";
    }
}

export function loginUser(email, password) {
    try {
        return axios.post('/loginuser', {
            email: email,
            password: password
        })
    } catch (e) {
        console.log("Erro: " + e);
        return "Conflito: " + e.toString();
    }
}



export function getCarrinho(distribuidorId, clienteId) {
    return axios.get('/carrinho/listar/' + distribuidorId + '/' + clienteId)
}

export function delCarrinho(produtoId, distribuidorId, clienteId) {
    return axios.post('/carrinho/remover', {
        produtoId, 
        distribuidorId, 
        clienteId
    })
}

