import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Footer (props) {
    return (
      <Typography variant="body2" color="text.secondary">
        {'Copyright © '}
        <Link color="inherit" href="https://pubevents.com.br/">
          PubEvents EditorX
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    )
  }
  