import React from "react";
import { useState } from "react";
import loginImg from "../../assets/login.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Alert from '@mui/material/Alert';
import { loginUser } from "./api";
import { useNavigate } from 'react-router-dom';

const Login = ({ onRegister, onReset, onTogglePassword, onShowPassword }) => {

  const [showWrongPass, setShowWrongPass] = useState(false);
  const [showUserNotFound, setUserNotFound] = useState(false);
  const [showNotAuth, setShowNotAuth] = useState(false);
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [emailComplete, setEmailComplete] = useState(false);

  const history = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handlePasswordChange = (e) => {
    setPass(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    //setEmail(e.target.value);
    // Verificar se o email é válido e habilitar o botão
    if (!isValidEmail(e.target.value)) {
      setEmailComplete(false);
    } else {
      setEmailComplete(true);
      setEmail(e.target.value);
    }
  };

  const validateForm = (e) => {
    // call api to register
    try {
      if (email && pass) {
        loginUser(email, pass).then( (value) => {
          if (value.status === 202) {
            setUserNotFound(true);
            setTimeout(() => {
              setUserNotFound(false);
            }, 3000);
            return;
          } else {
            if (value.status === 203) {
              setShowWrongPass(true)
              setTimeout(() => {
                setShowWrongPass(false);
              }, 3000);
              return;
            } else {
              if (value.status === 204) {
                  setShowNotAuth(true)
                  setTimeout(() => {
                    setShowNotAuth(false);
                  }, 3000);
                  return;
                } else {
                //console.log("Tok: " + value.data.token);
                console.log("Id: " + value.data.id);
                localStorage.setItem("authUser", value.data.token);
                localStorage.setItem("pubuser", value.data.id);
                //console.log("Vai..");
                history('/editorx');
              }
            }
          }
          //console.log("ret: " + ret);  
        });
      }
    } catch (e) {
      console.log("Error: " + e);
    }
    //alert("Funciona");
  };


  return (
    <div className="main-container --flex-center">
      <div className="img-container">
        <img src={loginImg} alt="login" />
      </div>
      <div className="form-container">
        <form className="--form-control">
          <h2 className="--color-danger --text-center">Login</h2>
          <input 
            type="text" className="--width-100" placeholder="Username"
            required
            onChange={handleEmailChange} 
          />
          <div className="password">
            <input
              type={onShowPassword ? "text" : "password"}
              className="--width-100"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            <span className="icon" onClick={onTogglePassword}>
              {onShowPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>
          <a 
            className={
              emailComplete
                ? "--btn --btn-primary --btn-block"
                : "--btn --btn-primary --btn-block btn-disabled"
            }
            href="/#" onClick={validateForm}>
              Login
          </a>          
          {/* <button href="#"
            className={
              emailComplete
                ? "--btn --btn-primary --btn-block"
                : "--btn --btn-primary --btn-block btn-disabled"
            }
            onClick={(e) => validateForm(e)}
            disabled={!emailComplete}
            ref={inputRef}
            >
              Login
            </button> */}
          <a href="/#" className="--text-sm" onClick={onReset}>
            Forgot password?
          </a>
          <span className="--text-sm --block">
            Don't have an account?{" "}
            <a href="/#" className="--text-sm" onClick={onRegister}>
              Register
            </a>
          </span>
          <div style={{position: "absolute"}} className={showWrongPass ? "show-wrongpass" : "hide-wrongpass"}>
            <Alert severity="warning" style={{fontSize: 15}}>
              Incorrect password.
            </Alert>
          </div>
          
          <div style={{position: "absolute"}} className={showUserNotFound ? "show-usernotfound" : "hide-usernotfound"}>
            <Alert severity="warning" style={{fontSize: 15}}>
              User not found.
            </Alert>
          </div>

          <div style={{position: "absolute"}} className={showNotAuth ? "show-notauth" : "hide-notauth"}>
            <Alert severity="warning" style={{fontSize: 15}}>
              User not authenticated.
            </Alert>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Login;
