import { useState, useEffect } from "react";
import registerImg from "../../assets/register.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaExclamationTriangle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { registerUser } from "./api";
import Alert from '@mui/material/Alert';

const Register = ({ onLogin, onShowPassword, onTogglePassword }) => {
  const [showIndicator, setShowIndicator] = useState(false);
  const [showRegError, setShowRegError] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [passLetter, setPassLetter] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [passChar, setPassChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const [passComplete, setPassComplete] = useState(false);
  const [emailComplete, setEmailComplete] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleShowIndicator = () => {
    setShowIndicator(true);
  };

  const handlePasswordChange = (e) => {
    setPass(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    //setEmail(e.target.value);
    // Verificar se o email é válido e habilitar o botão
    if (!isValidEmail(e.target.value)) {
      setEmailComplete(false);
    } else {
      setEmailComplete(true);
      setEmail(e.target.value);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const validateForm = (e) => {
    //alert(pass);
    // Desativa botão de registrar
    setPassComplete(false); 
    // call api to register
    try {
      registerUser(name, email, pass).then( (value) => {
        if (value.status === 209) {
          // Erro: usuário existente
          setShowRegError(true);
          setTimeout(() => {
            //setName("");
            //setEmail("");
            //setPass("");
            setShowRegError(false);
            // Ativa botão de registrar
            setPassComplete(true); 
            // recarrega a página
            window.location.reload(false);            
          }, 3000);
          return;
        } else {
          if (value.status === 201) {
            setShowSendEmail(true);
            setTimeout(() => {
              // limpa formulário
              //this.input_name.value = "";
              //setName("");
              //setEmail("");
              //setPass("");
              setShowSendEmail(false);
              // Ativa botão de registrar
              setPassComplete(false); 
              // recarrega a página
              window.location.reload(false);
            }, 3000);
            //alert("User registered!");
          }
        }
        //console.log("ret: " + ret);  
      });
    } catch (e) {
      console.log("Error: " + e);
    }
    //alert("Funciona");
  };

  useEffect(() => {
    // check Lower and Uppercase
    if (pass.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setPassLetter(true);
    } else {
      setPassLetter(false);
    }

    // Check For Numbers
    if (pass.match(/([0-9])/)) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }

    // Check For Special char

    if (pass.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setPassChar(true);
    } else {
      setPassChar(false);
    }

    if (pass.length > 7) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }

    if (passLetter && passNumber && passChar && passLength && emailComplete) {
      setPassComplete(true);
    } else {
      setPassComplete(false);
    }
  }, [pass, passLetter, passNumber, passChar, passLength, emailComplete]);

  return (
    <div className="main-container --flex-center">
      <div className="form-container">
        <form className="--form-control">
          <h2 className="--color-danger --text-center">Register</h2>
          <input type="text" className="--width-100" placeholder="Username" name="input_name"
            required 
            onChange={handleNameChange} />
          <input type="email" className="--width-100" placeholder="Email" name="input_email"
            required 
            onChange={handleEmailChange}
          />
          {/* PASSWORD FIELD */}
          <div className="password">
            <input
              type={onShowPassword ? "text" : "password"}
              className="--width-100"
              placeholder="Password"
              onFocus={handleShowIndicator}
              value={pass}
              onChange={handlePasswordChange}
              name="input_pass"
            />
            <span className="icon" onClick={onTogglePassword}>
              {onShowPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>
          {/* PASSWORD FIELD */}
          <button
            onClick={(e) => validateForm(e) /*alert(pass)*/}
            disabled={!passComplete}
            className={
              passComplete
                ? "--btn --btn-primary --btn-block"
                : "--btn --btn-primary --btn-block btn-disabled"
            }
          >
            Register
          </button>
          <div style={{position: "absolute"}} className={showRegError ? "show-regerror" : "hide-regerror"}>
            <Alert severity="warning" style={{fontSize: 15}}>
              User already registered. Please, go to login and recover your password.
            </Alert>
          </div>
          
          <div style={{position: "absolute"}} className={showSendEmail ? "show-sendemail" : "hide-sendemail"}>
            <Alert severity="warning" style={{fontSize: 15}}>
              An email was send to your account. Please validate your registry.
            </Alert>
          </div>
          

          <span className="--text-sm --block">
            Have an account?{" "}
            <a href="#0" className="--text-sm" onClick={onLogin}>
              Login
            </a>
          </span>
          {/* Pass Strength Indicator */}
          <div className={showIndicator ? "show-indicator" : "hide-indicator"}>
            <ul className="--list-style-none --card --bg-grey --text-sm --p">
              <p className="--text-sm">Password Strength Indicator</p>
              <li className={passLetter ? "pass-green" : "pass-red"}>
                <span className="--align-center">
                  {passLetter ? <FaCheck /> : <FaExclamationTriangle />}
                  &nbsp; Lowercase & Uppercase
                </span>
              </li>
              <li className={passNumber ? "pass-green" : "pass-red"}>
                <span className="--align-center">
                  {passNumber ? <FaCheck /> : <FaExclamationTriangle />}
                  &nbsp; Numbers (0-9)
                </span>
              </li>
              <li className={passChar ? "pass-green" : "pass-red"}>
                <span className="--align-center">
                  {passChar ? <FaCheck /> : <FaExclamationTriangle />}
                  &nbsp; Special Character (!@#$%^&*)
                </span>
              </li>
              <li className={passLength ? "pass-green" : "pass-red"}>
                <span className="--align-center">
                  {passLength ? <FaCheck /> : <FaExclamationTriangle />}
                  &nbsp; At least 8 Character
                </span>
              </li>
            </ul>
          </div>
          {/* Pass Strength Indicator */}
        </form>
      </div>
      <div className="img-container">
        <img src={registerImg} alt="login" />
      </div>
    </div>
  );
};

export default Register;
